.admin-door-select-wrapper {
  margin: 20px 0;

  .admin-door-select {
    position: relative;
    display: inline-block;
    width: 80%;
    background: #1c0129;
    border-radius: 20px;
    border: 4px solid #3f0357;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: center;

    &:hover {
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.4);
    }

    &.selected {
      background: lighten(#1c0129, 40%);
      border: 4px solid lighten(#3f0357, 40%);
      box-shadow: 0 5px 8px 0 lighten(rgba(0, 0, 0, 0.4), 40%);
    }
  }
}

.admin-door-reveal {
  .door-reveal-number {
    position: relative;
    margin: 20px 0;
  }

  .jumbo {
    transition: all 0.5s ease;
    background-color: rgba(255, 255, 255, 0);
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  &.revealed {
    .jumbo {
      scale: (1.2);
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
