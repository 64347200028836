@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap");

@font-face {
  font-family: "Insanibc";
  src: url("../fonts/Insanibc.eot");
  src: local("☺"), url("../fonts/Insanibc.woff") format("woff"),
    url("../fonts/Insanibc.ttf") format("truetype"),
    url("../fonts/Insanibc.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Another Danger Slanted";
  src: url("../fonts/Another Danger Slanted.eot");
  src: local("☺"), url("../fonts/Another Danger Slanted.woff") format("woff"),
    url("../fonts/Another Danger Slanted.ttf") format("truetype"),
    url("../fonts/Another Danger Slanted.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Insanibc";
  // font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
