.doors {
  // position: absolute;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 125px 0; /* Same height as divider */

  @media screen and (max-width: $xl) {
    flex-wrap: wrap;
  }

  .door-wrapper {
    position: relative;
    display: inline-block;
    max-width: 25%;
    transition: all 0.36s ease;

    @media screen and (max-width: $xl) {
      max-width: 100%;
    }
  }

  .door-wrapper:not(.inactive) {
    cursor: pointer;
    transition: transform 0.36s ease;
    &:hover {
      transform: scale(1.02);
    }
  }

  .door-wrapper.inactive {
    pointer-events: none;
  }

  // .door-wrapper .door:not(.shake).inactive {
  //   filter: grayscale(0.8);
  // }

  video.door {
    position: absolute;
    width: 100%;
    z-index: 85;
    left: 0;
    top: 0;
    // transition: opacity 0.5s ease;
    // transform: translate(-10%, 0%);

    &.option {
      z-index: 85;
    }

    &.correct {
      z-index: 90;
      opacity: 0;
    }

    &.incorrect {
      z-index: 90;
      opacity: 0;
    }
  }

  img.door-outline {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .door-info {
    position: relative;
    z-index: 90;
    margin-top: -27%;

    .door-number {
      position: relative;
      z-index: 100;
      background-image: linear-gradient(180deg, #3f0357 0%, #460266 100%);
      box-shadow: inset 0 -1px 4px 2px rgba(255, 255, 255, 0.25);
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 auto;

      .label,
      .jumbo {
        color: #ffffff;
        margin: 0;
        letter-spacing: 0;
        line-height: 1;
        text-align: center;
        text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
      }

      .label {
        font-size: 14px;
      }

      .jumbo {
        font-size: 32px;
        text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
      }
    }

    .door-well.well-purple {
      min-width: 75%;
      z-index: 95;
      padding: 35px 10px 10px;
      margin: -30px 10px 10px;
    }
  }
}

.shake {
  animation: shake 1s ease infinite alternate;
}

$shake-scale: 1;
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(-1deg) scale($shake-scale);
  }

  10% {
    transform: translate(-1px, -2px) rotate(0deg) scale($shake-scale);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg) scale($shake-scale);
  }

  30% {
    transform: translate(3px, 2px) rotate(-1deg) scale($shake-scale);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg) scale($shake-scale);
  }

  50% {
    transform: translate(-1px, 2px) rotate(1deg) scale($shake-scale);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg) scale($shake-scale);
  }

  70% {
    transform: translate(3px, 1px) rotate(1deg) scale($shake-scale);
  }

  80% {
    transform: translate(-1px, -1px) rotate(-1deg) scale($shake-scale);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg) scale($shake-scale);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale($shake-scale);
  }
}
