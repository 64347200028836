$GRID_PADDING: 30px;

.section-shadowcorns {
  margin-top: 100px;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: calc(25% - $GRID_PADDING);
    margin: 15px;
    transition: all 0.36s ease;

    @media screen and (max-width: $lg) {
      margin: 15px 0;
    }

    // cursor: pointer;

    &:hover {
      // transform: scale(1.02);

      // .shadowcorn {
      //   transform: scale(1.02);
      // }
    }

    .top,
    .shadowcorn,
    .bottom {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      width: 100%;
    }

    .img-wrapper,
    .shadowcorn {
      position: relative;
      z-index: 10;
      transition: all 0.36s ease;

      .shadowcorn-chains {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 15;
        display: none;
      }
    }

    .top,
    .bottom {
      position: relative;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      padding: 5px 10px;
      text-align: center;
      z-index: 5;
    }

    .top {
      border-radius: 15px 15px 0 0;
    }

    .bottom {
      border-radius: 0 0 15px 15px;

      p {
        font-size: 18px;
      }
    }

    &.buttons {
      .shadowcorn {
        border-radius: 0 0 15px 15px;
      }

      .bottom {
        background-color: transparent;
        box-shadow: none;
        z-index: 15;
      }

      button {
        margin-top: -30px;
        padding: 5px 5px 7px 5px;
        font-size: 1.5rem;
        // font-size: 1.25rem;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.state-unavailable {
      .shadowcorn-chains {
        display: block;
      }

      .shadowcorn {
        filter: grayscale(0.5);
      }
    }

    &.state-missed {
      .shadowcorn-chains {
        display: block;
      }

      .shadowcorn {
        filter: grayscale(0.25);
      }
    }
  }

  &.thirds {
    .item {
      width: calc(33.333% - $GRID_PADDING);

      @media screen and (max-width: $lg) {
        width: calc(50% - $GRID_PADDING);
      }

      @media screen and (max-width: $md) {
        width: 100%;
      }
    }
  }

  &.halves {
    .item {
      width: calc(50% - $GRID_PADDING);
    }
  }
}
