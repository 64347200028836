body {
  background-color: #282828 !important;
  color: $c-white !important;

  // background: url("../img/bg_full.jpg") no-repeat top center;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
}

// ************
// TYPOGRAPHY
// ************

.font-primary {
  font-family: "Insanibc" !important;
}

.font-secondary {
  font-family: "Quicksand", sans-serif !important;
}

.font-darkforest {
  font-family: "Another Danger Slanted";
}

h1 {
  font-family: "Insanibc";
  font-size: 64px;
  color: $c-white;
  letter-spacing: 0;
  margin-bottom: 0;

  @media screen and (max-width: $md) {
    font-size: 42px !important;
  }
}

h2 {
  font-family: "Insanibc";
  color: $c-white;
  letter-spacing: 0;
  text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
}

h3 {
  font-family: "Insanibc";
  font-size: 28px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 34px;
  text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
}

// h4 {
//   font-family: "Insanibc";
//   font-size: 21px;
//   color: #ffffff;
//   letter-spacing: 0;
//   text-align: center;
//   line-height: 34px;
//   text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
// }

h5 {
  font-family: "Quicksand", sans-serif;
}

p {
  font-family: "Quicksand", sans-serif;
  text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
}

figure {
  margin: 0;

  p,
  figcaption {
    text-shadow: 0 2px 4px rgba(28, 1, 41, 0.75);
  }
}

.blockquote {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.blockquote-footer {
  color: #fff;
  margin: 10px 0;
}

// ************
// BUTTONS
// ************

.btn-primary,
.btn-secondary {
  &.btn-small {
    padding: 10px 24px;
    font-size: 21px;
  }

  &:hover {
    -webkit-transform: translate(0, 1px);
    -ms-transform: translate(0, 1px);
    transform: translateY(1px);
  }

  &:active {
    border: none;
  }

  &:active:focus {
    -webkit-transform: translate(0, 2px);
    -ms-transform: translate(0, 2px);
    transform: translateY(2px);
    box-shadow: none;
  }

  .subtitle {
    margin-top: -5px;
    font-size: 1rem;
    // font-size: 0.9rem;
  }
}

.btn-primary {
  color: #fff;
  background-image: linear-gradient(180deg, #913dff 0%, #7733f4 100%);
  box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #6327d3;
  border-radius: 16px;
  border: none;
  padding: 16px 32px;
  // margin: 0 10px;
  transition: all 0.2s;
  font-family: "Insanibc";
  font-size: 28px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
    padding: 8px 16px;
    width: 100%;
  }

  &:hover,
  &:focus {
    box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.02), 0 0px 0 1px #6327d3;
    background-image: linear-gradient(180deg, #8b3bf5 0%, #7231eb 100%);
  }
  &.disabled {
    cursor: not-allowed;
  }

  // &.btn-sm {
  //   font-size: 20px;
  //   padding: 10px 24px;
  //   border-radius: 50px;
  //   box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #6327d3;
  // }

  &.btn-jumbo {
    margin: 30px 0 15px;
    font-size: 42px;
    padding: 18px 38px;
    box-shadow: 0 14px 18px 0 rgba(34, 33, 81, 0.35), 0 1px 0 1px #6327d3;

    @media screen and (max-width: 768px) {
      font-size: 32px !important;
      padding: 18px 38px !important;
    }
  }
}

.btn-secondary {
  color: $c-dark;
  background-image: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
  box-shadow: 0 10px 14px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #fefefe;
  border-radius: 16px;
  border: none;
  padding: 16px 32px;
  // margin: 0 10px;
  transition: all 0.2s;
  font-family: "Insanibc";
  font-size: 28px;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: $c-dark;
  }

  &:hover,
  &:focus {
    background-image: linear-gradient(
      180deg,
      darken(#ffffff, 5%) 0%,
      darken(#fafafa, 5%) 100%
    );
    box-shadow: 0 5px 8px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #fafafa;
  }

  &:disabled,
  &.disabled {
    color: $c-dark;
  }

  // &:hover {
  //   -webkit-transform: translate(0, 1px);
  //   -ms-transform: translate(0, 1px);
  //   transform: translateY(1px);
  // }
}

.spinner-border {
  border-width: 0.25rem !important;
}

.section-wrapper {
  position: relative;
}

.section {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px 4px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  position: relative;
  // backdrop-filter: blur(10px);
  padding: 30px;
  z-index: 5;
}

.rounded {
  border-radius: 16px !important;
}

.btn-primary-ingame {
  font-family: "Insanibc";
  background-image: url("../img/pattern_2.png"),
    radial-gradient(circle at 50% 50%, #ae70ff 0%, #7733f4 70%);
  // box-shadow: 0 6px 9px 0 rgba(34, 33, 81, 0.25), 0 1px 0 1px #6327d3;
  box-shadow: 0 3px 0 1px darken(#6327d3, 12%),
    inset 0 1px 0 0px lighten(#6327d3, 15%);
  border-radius: 100px;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.5);
  // border: 5px solid #8f67d1;
  cursor: pointer;
  width: 100%;
  text-align: center;

  &:hover,
  &:focus {
    background-image: url("../img/pattern_2.png"),
      radial-gradient(
        circle at 50% 50%,
        darken(#ae70ff, 4%) 0%,
        darken(#7733f4, 2%) 70%
      );
    box-shadow: 0 1px 0 1px darken(#6327d3, 12%),
      inset 0 1px 0 0px lighten(#6327d3, 15%);
  }

  .title {
  }

  .subtitle {
    margin-top: -5px;
    font-size: 1rem;
    // font-size: 0.9rem;
  }

  &.state-selected {
    background-image: url("../img/pattern_2.png"),
      radial-gradient(
        circle at 50% 50%,
        darken(#ffd373, 28%) 0%,
        darken(#ffa312, 12%) 70%
      );
    box-shadow: 0 6px 9px 0 rgba(182, 68, 7, 0.4), 0 1px 0 1px #b64407,
      inset 0 1px 0 0px lighten(#ffa312, 10%);

    &:hover,
    &:focus {
      background-image: url("../img/pattern_2.png"),
        radial-gradient(
          circle at 50% 50%,
          darken(#ffd373, 33%) 0%,
          darken(#ffa312, 18%) 70%
        );
    }
  }

  &.state-unavailable {
    filter: grayscale(1);
    pointer-events: none;
  }

  &.state-prevStaked {
    background-image: url("../img/pattern_2.png"),
      radial-gradient(circle at 50% 50%, #4ba3fe 0%, #4961e5 70%);
    box-shadow: 0 3px 0 1px darken(#4961e5, 12%),
      inset 0 1px 0 0px lighten(#4961e5, 15%);
  }
}

.form-check-input {
  box-shadow: none !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
  width: 2.2em !important;
  height: 1.2em;

  &:checked {
    background-color: #632e7b;
  }

  &:not(:checked):focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
  }
}

// ************
// MAIN BODY
// ************

.main {
  position: relative;
  z-index: 5;
  padding-bottom: 100px;
  min-height: 100vh;

  background: url(../img/bg_main.jpg) repeat top center;
  background-size: 100% auto;

  .divider {
    position: absolute;
    display: flex;
    top: calc(0px - 125px);
    width: 100%;
    z-index: 75;
    box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.4);
    height: 125px;
    //   background: url(../img/divider.jpg) no-repeat center center;
    //   background-size: cover;

    .container {
      position: relative;
      z-index: 80;
    }

    .bg-divider {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    h2 {
      @media screen and (max-width: $md) {
        font-size: 18px;
      }
    }
  }
}

.section-buttons {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

// ************
// ONBOARD
// ************

// .bn-onboard-modal {
//   z-index: 9999;
// }

// .bn-onboard-modal-content-header-heading {
//   color: inherit;
//   text-shadow: none;
// }

// .bn-onboard-modal-content-close {
//   padding: 10px !important;
// }

body iframe {
  pointer-events: none;
}

// ************
// TOOLTIPS
// ************

.tooltip-inner {
  border-radius: 10px;
  font-family: "Quicksand", sans-serif !important;
}
